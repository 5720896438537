import Box from '@mui/material/Box';
import type { NextPage } from 'next';
import { GetServerSidePropsContext } from 'next';
import Head from 'next/head';
import { BasicLoanDto, CompanyListDto, OnboardDTO, ViewType } from 'src/backend';
import { AuthGuard } from 'src/components/auth/auth-guard';
import { BorrowerOnboarding } from 'src/components/borrower-onboarding';
import { DashboardLayout } from 'src/components/dashboard/dashboard-layout';
import { LoansFilters } from 'src/components/loans/loans-filters';
import { LoansKanbanMapper } from 'src/components/loans-kanban';
import { SplashScreen } from 'src/components/splash-screen';
import { InvitesBanner } from 'src/components/user/invites-banner';
import { LoanRoleGuard } from 'src/components/user/loan-role-guard';
import { UserRoleGuard } from 'src/components/user/user-role-guard';
import { LoanStageCategory } from 'src/constants/loan';
import {
  LENDER_VIEW,
  PRINCIPAL_KANBAN_VIEW,
  PRINCIPAL_VIEW,
} from 'src/constants/person';
import { PageLabel } from 'src/constants/ui';
import { Permission } from 'src/constants/user';
import { CustomizedTableProvider } from 'src/contexts/customized-table-context';
import { KanbanLoansProvider } from 'src/contexts/loans-kanban-context';
import { useDashboardLayout } from 'src/hooks/use-dashboard-layout';
import { useNavbarTitle } from 'src/hooks/use-navbar-title';
import { useRedirectBorrower } from 'src/hooks/use-redirect-borrower';
import { useUser } from 'src/hooks/use-user';
import { getData } from 'src/utils/fetch-data';
import { roleCan } from 'src/utils/user/role-can';

const ViewTypeComponent = {
  [LENDER_VIEW]: <LoansKanbanMapper />,
  [PRINCIPAL_VIEW]: <BorrowerOnboarding />,
  [PRINCIPAL_KANBAN_VIEW]: <LoansKanbanMapper />,
};

const Home: NextPage = () => {
  useNavbarTitle(PageLabel.Origination);
  const viewType = useDashboardLayout();
  useRedirectBorrower();
  const { userRole, isLender, company, user } = useUser();

  if (!user) {
    return <SplashScreen />;
  }

  return (<>
    <Head>
      <title>{PageLabel.Origination}</title>
    </Head>
    <UserRoleGuard permission={Permission.SeePendingInvites}>
      <InvitesBanner />
    </UserRoleGuard>
    <KanbanLoansProvider
      phaseCategory={LoanStageCategory.ORIGINATION}
      companyId={company?.id}>
      <CustomizedTableProvider>
        <Box
          className="bg-black-10"
          component="main"
          sx={{
            flexGrow: 1,
            '& .loans-kanban-container': {
              height: `calc(100vh - ${isLender && roleCan(userRole, Permission.CrudLoan)
                ? '140'
                : '56'
                }px)`,
            },
          }}
        >
          {viewType !== PRINCIPAL_VIEW && <LoanRoleGuard permission={Permission.FilterLoans}>
            <LoansFilters lenderId={company?.id} />
          </LoanRoleGuard>}
          {ViewTypeComponent[viewType]}
        </Box>
      </CustomizedTableProvider>
    </KanbanLoansProvider>
  </>);
};

Home.getLayout = (page) => (
  <AuthGuard>
    <DashboardLayout>
      {page}
    </DashboardLayout>
  </AuthGuard>
);


// Usage example:
// const result = await fetchData(`${process.env.API_BASE_URL}/api/v2/users/loggedUser`, {
//   headers: context.req.headers,
// });
export async function getServerSideProps(context: GetServerSidePropsContext) {
  context.res.setHeader('Cache-Control', 'no-store');
  // if we don't have a cookie return props
  if (!context.req.headers.cookie) {
    return {
      props: {},
    };
  }
  try {

    // get logged in user
    const user = await getData<OnboardDTO>('/api/v2/users/loggedUser', {
      headers: {
        'Cookie': context.req.headers.cookie,
      }
    })
    // if user is not borrower return props
    if (user.viewType !== ViewType.PRINCIPAL) {
      return {
        props: {},
      };
    }
    const referrer = context.req.headers.referer;
    const currentDomain = context.req.headers.host;

    // get only the domain from the referrer
    const referrerDomain = referrer ? new URL(referrer).host : null;
    const referrerIsSameDomain = referrerDomain?.includes(currentDomain);
    const referrerIsNotSameDomain = referrerDomain && !referrerIsSameDomain;
    const referrerIsAuthPage = referrerIsSameDomain && referrer?.includes('/auth/');
    const referrerIsLearningCenter = referrerIsSameDomain && referrer?.includes('v2/learning-center');
    const referrerIsApiAction = referrerIsSameDomain && referrer?.includes('api/v1/action');
    // if referrer is not null
    // and referrer is not the same domain as the current domain
    // and user is a borrower, redirect to portal
    if (!referrer ||
      referrerIsNotSameDomain ||
      referrerIsLearningCenter ||
      referrerIsApiAction ||
      referrerIsAuthPage) {
      const lenders = await getData<CompanyListDto[]>('/api/v1/lenders/forBorrower', {
        headers: {
          'Cookie': context.req.headers.cookie,
        }
      })
      // if we only have one lender 
      // redirect to borrower portal for that lender
      if (lenders.length === 1) {
        return {
          redirect: {
            destination: `/portal/${lenders[0].id}`,
            permanent: false,
          },
        };
      }
      // otherwise
      // redirect to the portal
      // and let borrower select the lender
      return {
        redirect: {
          destination: '/portal',
          permanent: false,
        },
      };
    }

    // if user has only one loan and is a borrower
    // and user has on boarded,
    // redirect to loan details
    // get loans for borrower user
    const loans = await getData<BasicLoanDto[]>('/api/v1/loans/view', {
      headers: {
        'Cookie': context.req.headers.cookie,
      }
    })
    if (loans.length === 1 && !user.needOnboard) {
      return {
        redirect: {
          destination: `/loans/${loans[0].id}`,
          permanent: false,
        },
      };
    }

  } catch (error) {
    console.error(error, context.req.headers.cookie);
  }
  return {
    props: {},
  };
}

export default Home;
